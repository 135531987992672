
@import '../assets/css/scss-variables.scss';

.comp-accordion-single-entry {
  --padding-inline: 0.75rem;
  --padding-block: 2.5rem;
  --_transition-speed-fast: calc(var(--transition-speed) * 3);
  --_transition-speed-slow: calc(var(--transition-speed) * 5);
  
  display: grid;
  grid-template-columns: 1fr 1fr 2em;
  column-gap: var(--column-gap);
  
  @media (min-width: $mediaquery-tablet-landscape) {
    align-items: start;
  }
  
  .accordion-detailed-services {
    
    .accordion-detailed-service {
      font-weight: 600;
      opacity: var(--opacity-medium);

      &:not(:last-child):after {
        content: ", ";
      }
    }
  }
    
  .accordion-trigger {
    display: inherit;
    grid-template-columns: inherit;
    grid-template-rows: min-content;
    gap: inherit;
    
    grid-column: 1 / -1;
    grid-row: 1;
    width: 100%;
    
    background: none;
    border: 0;
    text-align: left;
    cursor: pointer;
    z-index: 10;
    
    padding: var(--padding-block) var(--padding-inline);
    
    @media (min-width: $mediaquery-tablet-landscape) {
      height: -webkit-fill-available;
      padding-inline: 0;
      grid-row: 1 / -1;
    }
    
    & > * + * {
      margin-top: .5em;
    }
    
    .accordion-headline,
    .accordion-detailed-services {
      grid-column: 1 / span 2;
      
      @media (min-width: $mediaquery-tablet-landscape) {
        grid-column: 1;
      }
    }
    
    .accordion-headline {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5em;
      
      & > * {
        flex-basis: auto;
      }
    }
  }
  
  .accordion-icon {
    --_icon-width: 0.25em;
    --_icon-height: 1.25em;

    grid-column: 3;
    grid-row: 1 / -1;
    
    display: grid;
    justify-self: end;
    place-items: center;
    margin-top: unset;
    
    transition:
      rotate var(--_transition-speed-slow) var(--transition-easing),
      translate var(--_transition-speed-fast) var(--transition-easing);
    
    .plus-line {
      background-color: var(--black);
      grid-row: 1;
      grid-column: 1;
      
      &.plus-line-horizontal {
        width: var(--_icon-width);
        height: var(--_icon-height);
      }
      
      &.plus-line-vertical {
        width: var(--_icon-height);
        height: var(--_icon-width);
      }
      
      @at-root {
        .accordion-trigger {
          --_translate-distance: 0.4em;
          
          .accordion-headline {
            transition: var(--_transition-speed-fast) var(--transition-easing);
            vertical-align: middle;
            transform: perspective(1px) translateZ(0);
          }
          
          &:hover,
          &:focus-visible {
            
            .accordion-headline {
              /* translate: var(--_translate-distance) 0em; */
              /* animation-name: buzz-out-on-hover;
              animation-duration: 500ms;
              animation-timing-function: linear;
              animation-iteration-count: 1; */
            }

            .accordion-icon {
              rotate: 90deg;
              /* translate: calc(var(--_translate-distance) * -1) 0em; */
              /* animation-name: propeller;
              animation-duration: 1000ms;
              animation-timing-function: ease-in-out;
              animation-iteration-count: infinite; */
            }
          }

          &[aria-expanded="true"]:hover,
          &[aria-expanded="true"]:focus-visible,
          &[aria-expanded="true"] {
            .accordion-icon {
              rotate: 45deg;
            }
          }
        }
      }
    }
  }
  
  .accordion-contents {
    padding: 0 var(--padding-inline);
    /* optical alignment */
    translate: 0 -0.175em;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows var(--_transition-speed-slow) var(--transition-easing);
    grid-column: 1 / -1;
    
    &:not([hidden]) {
      grid-template-rows: 1fr;
    }
    
    @media (min-width: $mediaquery-tablet-portrait) {
      grid-column: 1 / 3;
    }
    
    @media (min-width: $mediaquery-tablet-landscape) {
      grid-column: 2;
      grid-row: 1 / -1;
      padding-block-start: var(--padding-block);
      padding-inline: 0;
    }
    
    & > * {
      overflow: hidden;
      grid-row: 1 / span 2;
      
      &:after {
        content: "";
        display: inline-block;
        height: var(--padding-block);
        width: 100%;
        /* optical alignment */
        margin-bottom: -0.6em;
      }
    }
  }
}
